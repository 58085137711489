import { createContext, ReactNode, useContext } from "react";
import { useRediaPlatform } from "./useRediaPlatform";

export type RediaPlatformContext = ReturnType<typeof useRediaPlatform>;

const initialState: RediaPlatformContext = {
  rediaPlatform: undefined,
  user: undefined,
  isSessionReady: false,
  isRediaPlatformConfigured: false,
  toggleUseMockClient: () => null,
};

const Context = createContext(initialState);

export const useRediaPlatformContext = () => useContext(Context);

export function RediaPlatformProvider({ children }: { children: ReactNode }) {
  const rediaPlatform = useRediaPlatform();

  return <Context.Provider value={rediaPlatform}>{children}</Context.Provider>;
}
