import {
  PatronReservation,
  RediaPlatform,
  Session,
  SessionToken,
  Schemas,
  RediaPlatformProps,
} from "../client/interfaces";
import { mockLoans } from "./mockLoans";
import { mockPatron } from "./mockPatron";
import { mockPublications } from "./mockPublications";
import { mockReservation, mockReservations } from "./mockReservations";
import { KeyValueStore, createSessionStore } from "../client/sessionStore";

const mockDataLocalStorageKey = "mock-data";
export const mockLoansLocalStorageKey = mockDataLocalStorageKey + "-loans";
export const mockReservationsLocalStorageKey = mockDataLocalStorageKey + "-reservations";

export const createRediaPlatformMockClient = (props: RediaPlatformProps): RediaPlatform =>
  new RediaPlatformMockClient(props);

class RediaPlatformMockClient implements RediaPlatform {
  private onSessionChange: ((session: Session) => void) | undefined;
  private token: SessionToken;
  public environment = "test";
  public customerId = "testbib";
  private sessionStore: KeyValueStore<Session>;
  public readonly sessionStorageKey = "libry-content-mock-session";
  public readonly isMock = true;

  public constructor({ onSessionChange }: { onSessionChange?: (session: Session | undefined) => void } = {}) {
    this.onSessionChange = onSessionChange;
    this.token = { type: "bearer", token: "21", expiresTime: "2021-01-01T00:00:00.000Z" };
    this.sessionStore = createSessionStore<Session>({
      storage: sessionStorage,
      key: this.sessionStorageKey,
      onChange: onSessionChange,
    });
  }

  public async login(username: string, password: string) {
    if (this.onSessionChange) this.sessionStore.set({ token: this.token, user: mockPatron });
  }

  public async logout() {
    this.sessionStore.clear();
  }

  public async getConfiguration() {
    const config: Schemas["configuration"]["ConfigurationContent"] = {
      productCode: "content",
      name: "Testbiblioteket",
    };
    return config;
  }

  public async getCustomerGroups() {
    return [];
  }

  public getUser() {
    return mockPatron;
  }

  public async refreshUserProfile() {
    // Do nothing
  }

  public async getReservations(): Promise<PatronReservation[]> {
    return getFromLocalStorage(mockReservationsLocalStorageKey) ?? mockReservations;
  }

  public async deleteReservation(reservationId: string) {
    // Do nothing
  }

  public async createReservation(reservationId: string, pickupBranchCode: string): Promise<PatronReservation> {
    return mockReservation;
  }

  public async getLoans() {
    return getFromLocalStorage(mockLoansLocalStorageKey) ?? mockLoans;
  }

  public async renewLoan(loanId: string) {
    const loan = mockLoans[0];
    if (!loan) {
      // Make TS happy
      throw new Error("Loan not found");
    }
    return loan;
  }

  public async getPublications(publicationIds: string[]) {
    return mockPublications;
  }
}

const getFromLocalStorage = (key: string) => {
  const localStorageValue = localStorage.getItem(key);
  if (!localStorageValue) return undefined;
  try {
    return JSON.parse(localStorageValue);
  } catch (e) {
    console.log("Ugyldig data i local storage");
    return undefined;
  }
};
